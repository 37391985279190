@each $name, $value in $theme-colors {

  .fill-#{$name} {
    fill: $value;
  }

  .stroke-#{$name} {
    stroke: $value;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
  }

  .bg-#{$name} {
    background-color: $value;
  }

  .color-#{$name} {
    color: $value !important;
  }

}

.fill-white {
  fill: $white;
}

.fill-black {
  fill: $black;
}

.stroke-white {
  stroke: $white;
  fill: none;
}

.stroke-black {
  stroke: $black;
  fill: none;
}
