.section-pt-md {
  padding-top: $grid-gutter-width * 1.25;
  @include media-breakpoint-up(sm) {
    padding-top: $grid-gutter-width * 1.75
  }
  @include media-breakpoint-up(md) {
    padding-top: $grid-gutter-width * 2.5;
  }
}

.section-pb-md {
  padding-bottom: $grid-gutter-width * 1.25;
  @include media-breakpoint-up(sm) {
    padding-bottom: $grid-gutter-width * 1.75
  }
  @include media-breakpoint-up(md) {
    padding-bottom: $grid-gutter-width * 2.5;
  }
}

.section-mt-md {
  margin-top: $grid-gutter-width * 1.25;
  @include media-breakpoint-up(sm) {
    margin-top: $grid-gutter-width * 1.75
  }
  @include media-breakpoint-up(md) {
    margin-top: $grid-gutter-width * 2.5;
  }
}

.section-pb-lg {
  padding-bottom: $grid-gutter-width * 1.5;
  @include media-breakpoint-up(sm) {
    padding-bottom: $grid-gutter-width * 2;
  }
  @include media-breakpoint-up(md) {
    padding-bottom: $grid-gutter-width * 3;
  }
}

.section-pt-lg {
  padding-top: $grid-gutter-width * 1.5;
  @include media-breakpoint-up(sm) {
    padding-top: $grid-gutter-width * 2;
  }
  @include media-breakpoint-up(md) {
    padding-top: $grid-gutter-width * 3;
  }
}

.cross {
  width: 100%;
  aspect-ratio: 1/1;
  position: relative;
  &:before,
  &:after {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    background-color: $primary;
    position: absolute;
    top: 0;
    left: 50%;
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

.circle {
  border-radius: 1000px;
  overflow: hidden;
}

.semi-circle {
  border-top-left-radius: 1000px;
  border-top-right-radius: 1000px;
  overflow: hidden;
}

.left {
  transform: scaleX(-1);
}

.blend-mode-multiply {
  mix-blend-mode: multiply;
}
