.a-zoom {
  img {
    transform: scale(1);
    transition: $transition-base;
  }
  &:hover,
  &:focus {
    img {
      transform: scale(1.1);
    }
  }
}
