.cms-text {
  word-break: break-word;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  table,
  ol,
  ul {
    letter-spacing: $letter-spacing;
    margin-bottom: 1.875rem;
    line-height: 1.8;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    list-style: none;
    li {
      &:before {
        content: "\2022";
        color: $danger;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }

  strong {
    font-weight: $font-weight-semibold;
  }

  h5,
  h6 {
    line-height: 1.5;
    @include rfs(1.25rem, font-size);

  }

  img {
    max-width: 100%;
  }

  a {
    &,
    strong {
      font-weight: $font-weight-semibold;
      color: $secondary;
    }
  }

  strong {
    color: $primary;
    font-weight: $font-weight-semibold;
  }

  h1,
  h2,
  h3 {
    &,
    * {
      line-height: 1.4;
      font-weight: $font-weight-normal;
    }
  }

  h1,
  h2 {
    font-size: $h3-font-size;
  }

  h3 {
    font-size: $h4-font-size;
    &,
    * {
      font-weight: $font-weight-semibold;
    }
  }

  ul,
  ol {
    padding-left: $grid-gutter-width * 0.5;
    li + li {
      margin-top: 15px;
    }
  }

}

[class*='fs-'] {
  letter-spacing: $letter-spacing;
}

[class*='display-'] {
  letter-spacing: $letter-spacing;
  font-weight: $headings-font-weight;
}

h1,
h2,
h3,
h4,
h5 {
  letter-spacing: $letter-spacing;
}

.fs-20 {
  line-height: 1.8;
}
