html.site-menu-open #site-nav {

  .hamburger {

    &:hover .hamburger-box {
      transform: scale(1.25) rotate(90deg);
    }

    .hamburger-box {
      transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
      transition-duration: 0.4s;
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background: $navbar-light-color;
    }
  }

}

.l-site-nav {

  $nav-breakpoint: 'lg';

  position: fixed;
  z-index: $zindex-offcanvas;
  transition: $transition-base;

  .navbar-brand {
    margin: 0;
    img {
      height: 1.5rem;
      max-width: 50vw;
    }
  }

  .dropdown {
    .nav-item:not(.dropdown-item),
    .nav-link:not(.dropdown-item) {
      line-height: 1;
      display: flex;
      align-items: center;
      letter-spacing: $letter-spacing;
      svg {
        margin-left: 12px;
        transition: $transition-base;
      }
      &:hover,
      &:focus,
      &.active {
        svg {
          stroke: $primary;
        }
      }
    }
    .dropdown-menu {
      border: solid 1px $dropdown-border-color;
      left: 50%;
      transform: translateX(-50%);
      .dropdown-item {
        padding: $dropdown-item-padding-y $dropdown-item-padding-x;
        text-align: center;
        font-weight: 500;
      }
    }
  }

  .navbar-languages {
    > .nav-link {
      border: solid 1px $secondary;
      height: 100%;
      padding: 0 14px;
      justify-content: center;
      aspect-ratio: 1/1;
      &:hover,
      &:focus,
      &:active {
        background-color: $secondary;
        color: $white;
      }
    }
    .dropdown-menu {
      right: 0;
      width: auto;
      min-width: 10px;
      padding: 10px 0;
      margin: 20px 0 0 0;
      border-radius: $border-radius-sm;
      transform: none;
      left: 0;
      right: 0;
      li {
        a {
          text-transform: uppercase;
          font-weight: $font-weight-semibold;
          color: $primary;
          line-height: 1;
          padding: 5px 0 6px;
          text-align: center;
          letter-spacing: $letter-spacing;
          &:hover,
          &:focus,
          &.active {
            color: $secondary;
            background-color: $white;
          }
        }
      }
    }
  }

  .hamburger {
    z-index: $zindex-tooltip * 10;
  }

  + div#skip-to-content {
    main:not(.no-padding-top) {
      padding-top: 111px;
      @include media-breakpoint-up(sm) {
        padding-top: 151px;
      }
    }
  }

  .js-site-nav-dropdown {
    &:hover {
      .dropdown-menu {
        display: block;
        z-index: 1;
        top: calc(100%);
      }
    }
  }

  &.navbar-expand-lg .navbar-nav .nav-link {
    @include media-breakpoint-only(lg) {
      padding-left: $nav-link-padding-x * 0.75;
      padding-right: $nav-link-padding-x * 0.75;
    }
  }

}
