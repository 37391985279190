// Settings
// ==================================================
$hamburger-padding-x           : 1.25rem;
$hamburger-padding-y           : 0.5rem;
$hamburger-layer-width         : 30px;
$hamburger-layer-height        : 1px;
$hamburger-layer-spacing       : 6px;
$hamburger-layer-color         : $primary;
$hamburger-layer-border-radius : 2px;
$hamburger-hover-opacity       : 1;
$hamburger-active-layer-color  : $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false;
$hamburger-hover-filter       : opacity(50%);
$hamburger-active-hover-filter: $hamburger-hover-filter;

// Types (Remove or comment out what you don’t need)
// ==================================================
// @formatter:off
$hamburger-types: (
  3dx,
);
// @formatter:on

.hamburger {
  .hamburger-box {
    $size: 51px;
    width: $size;
    height: $size;
    border: 1px solid transparent;
    border-radius: 50%;
  }
  &.is-active .hamburger-box {
    border: 1px solid $navbar-light-color;

  }

}
