.l-widget--image-text {
  &:not(.l-widget-condensed) {
    img {
      max-width: 100%;
      height: auto;
      margin-left: -25%;
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: $grid-gutter-width * 0.5;
        bottom: 0;
        height: 100%;
        max-width: unset;
        margin-left: 0;
      }
    }
    [class*='row-reverse'] {
      img {
        margin-left: 25%;
        @include media-breakpoint-up(lg) {
          right: auto;
          left: $grid-gutter-width * 0.5;
          margin: 0;
        }
      }
    }
  }
  &.l-widget-condensed {
    img {
      height: auto;
      width: 100%;
    }
  }
}
