.c-card-seo {
  .icon {
    stroke: $primary;
    transition: $transition-base;
  }
  .caption {
    flex: 1 1 auto;
  }
  &:hover,
  &:focus,
  &.active {
    .icon {
      stroke: $secondary;
    }
  }
}
