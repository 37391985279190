* {
  @include font-smoothing(on);

  &::selection {
    color: $body-bg;
    background: lighten($primary, 10%);
  }
}

html {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 100%;
}

body {
  word-break: break-word;
}

main {
  flex-shrink: 0;
}

.container-fluid {
  max-width: $container-fluid-max-width;
  padding-left: $grid-gutter-width * 0.75;
  padding-right: $grid-gutter-width * 0.75;
  @include media-breakpoint-up(sm) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
  @include media-breakpoint-up(lg) {
    padding-left: $grid-gutter-width * 1.5;
    padding-right: $grid-gutter-width * 1.5;
  }
}

.bg-none {
  background: none !important;
}

.bg-gradient {
  background: $gradient;
}

pre {
  overflow: visible;
}

.circle-pill {
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 100%;
}

abbr[title] {
  text-decoration: none;
}


.cursor {
  display: none;
  @media (hover: hover) {
    display: block;
    width: $grid-gutter-width * 0.3;
    height: $grid-gutter-width * 0.3;
    border-radius: 100%;
    transition: all 200ms ease-out;
    position: fixed;
    pointer-events: none;
    z-index: $zindex-modal-backdrop + 10;
    left: 0;
    top: 0;
    background-color: $secondary;
    opacity: 0;
  }
}
