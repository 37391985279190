main#page-blog-detail {

  .l-widget--image {
    img.circle {
      max-width: 55rem;
      width: 100%;
    }
  }

}
