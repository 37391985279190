.c-card-team {
  .img-wrapper {
    position: relative;
    border-radius: 100%;
    border: solid 1px $primary;
    overflow: hidden;
    img {
      mix-blend-mode: multiply;
      + img {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
  h2 {
    line-height: 1.2;
  }
  p {
    color: $primary;
    transition: $transition-base;
    line-height: 1.2;
  }
  &:hover,
  &:focus {
    p {
      color: $secondary;
    }
    .img-wrapper {
      img {
        opacity: 0;
        + img {
          opacity: 1;
        }
      }
    }
  }
}
