.list-bullet {

  $bulletSize: 4px;
  $bulletSpacer: 10px;

  $bulletSizeSM: 6px;
  $bulletSpacerSm: 22px;

  display: flex;
  flex-wrap: wrap;
  margin-right: -$bulletSpacer;
  margin-left: -$bulletSpacer;

  li {
    display: flex;
    align-items: center;
    margin: 0;
    span,
    a {
      display: block;
      color: $primary;
      text-decoration: none;
      padding: 0 $bulletSpacer;
    }
    &:not(:last-child) {
      margin: 0;
      &:after {
        position: relative;
        top: 1px;
        content: '';
        display: inline-block;
        width: $bulletSize;
        height: $bulletSize;
        border-radius: $bulletSize * 0.5;
        background-color: $primary;
      }
    }
  }

  &:not(.list-inverted) {
    a:hover,
    a:focus {
      color: $secondary;
    }
  }

  &.list-bullet-secondary {
    li {
      &:not(:last-child) {
        margin: 0;
        &:after {
          background-color: $secondary;
        }
      }
    }
  }

  &.list-inverted {
    li {
      a,
      span {
        color: $white;
      }
      &:after {
        background-color: $white;
      }
    }

  }
  &.list-sm {
    margin-right: -$bulletSpacerSm;
    margin-left: -$bulletSpacerSm;
    li {
      a,
      span {
        padding: 0 $bulletSpacerSm;
      }
      &:not(:last-child) {
        &:after {
          width: $bulletSizeSM;
          height: $bulletSizeSM;
          border-radius: $bulletSizeSM * 0.5;
        }
      }
    }
  }

}
