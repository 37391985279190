main#page-contact {

  #contact {
    a {
      position: relative;
      border-top: solid 1px rgba($primary, 15%);
      display: block;
      color: $primary;
      text-decoration: none;
      padding: $grid-gutter-width $grid-gutter-width 0 0;
      transition: $transition-base;
      span {
        display: block;
        &:first-child {
          color: $primary;
          transition: $transition-base;
        }
      }
      .icon {
        position: absolute;
        right: 0;
        bottom: 3px;
        stroke-width: 0.5;
        transition: $transition-base;
      }
      &:hover,
      &:focus {
        padding: $grid-gutter-width $grid-gutter-width 0 $grid-gutter-width * 2;
        .icon {
          right: calc(100% - $grid-gutter-width);
        }
        span:first-child {
          color: $secondary;
        }
      }
    }
  }

}
