.l-widget--quote {
  $logoSize: 100px;
  .logo {
    border: solid 1px $primary;
    border-radius: $logoSize * 0.5;
    width: $logoSize;
    overflow: hidden;
    img {
      mix-blend-mode: multiply;
      padding: 5px;
    }
  }
  .author {
    span + span {
      padding-top: 5px;
    }
  }
}
