.cursor {
  &.cursor-link {

    $size: 180px;

    pointer-events: none;
    width: $size;
    height: $size;
    border-radius: $size * 0.5;
    transform: translate(-50%, -50%);

    svg {
      width: 25%;
      height: 25%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @include media-breakpoint-down(sm) {
      width: $size * 0.75;
      height: $size * 0.75;
    }

    @media (hover: none) and (pointer: coarse) {
      display: none;
    }

  }
}
