@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/vendor/rfs";

// Custom
$container-fluid-max-width: 125rem;
$z-index-cookies: 10000;
$letter-spacing: 0.063rem;

// scss-docs-start gray-color-variables
$gray-100: #EDEBE4;
// scss-docs-end gray-color-variables

// scss-docs-start color-variables
$blue:    #002856;
$red:     #F5231D;
$green:   #24B332;
$white:   #fff;
// scss-docs-end color-variables

// scss-docs-start theme-color-variables
$primary:       $blue;
$secondary:     $red;
$success:       $green;
$danger:        $red;
$light:         $gray-100;
$dark:          $primary;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);
// scss-docs-end theme-colors-map

// Options
$enable-negative-margins: true;
$enable-validation-icons: false;

// Gradient
$gradient: linear-gradient(-180deg, rgba($white, 1), rgba($gray-100, 1));

// Spacing
$spacer: 0.625rem;
$spacers: (
  0: 0,
  1: $spacer,
  2: $spacer * 2,
  3: $spacer * 3,
  4: $spacer * 4,
  5: $spacer * 5,
  6: $spacer * 6,
  7: $spacer * 7,
  8: $spacer * 8,
  9: $spacer * 9,
  10: $spacer * 10,
  12: $spacer * 12,
  16: $spacer * 16,
);

// scss-docs-start position-map
$position-values: (
  0: 0,
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
);
// scss-docs-end position-map

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $gray-100;
$body-color:                $primary;

// Grid columns
$grid-gutter-width: 2.5rem;
$container-padding-x: $grid-gutter-width * 0.625;
$gutters: $spacers;

// Components
//
// Define common padding and border radius sizes and more.
$border-color:                $primary;

$box-shadow:                  none;
$box-shadow-inset:            none;

// Typography
$font-family-sans-serif:      ballinger, Helvetica, Arial, sans-serif;

$font-weight-lighter:         200;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-semibold:        500;
$font-weight-bold:            700;

$line-height-base:            1.2;
$line-height-md:              1.8;

$h1-font-size:                $font-size-base * 3.125;
$h2-font-size:                $font-size-base * 2.5;
$h3-font-size:                $font-size-base * 1.875;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  10: 0.625rem,
  12: 0.75rem,
  14: 0.875rem,
  16: 1rem,
  18: 1.125rem,
  20: 1.25rem,
  22: 1.39rem,
  24: 1.5rem,
);

// scss-docs-start headings-variables
$headings-font-family:        $font-family-base;
$headings-font-weight:        $font-weight-lighter;
$headings-line-height:        1.2;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-font-sizes: (
  1: 3.75rem,
  2: 3.5rem,
  3: 3.125rem,
  4: 2.5rem,
  5: 1.875rem,
  6: 1.25rem
);

$display-font-weight: 200;
$display-line-height: 1.1;

$headings-margin-bottom: $spacer * 2;

$border-radius:               .375rem;
$border-radius-sm:            0.625rem;
$border-radius-lg:            .5rem;
$border-radius-xl:            1rem;
$border-radius-2xl:           2rem;
$border-radius-pill:          50rem;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y:         1.125rem;
$input-btn-padding-x:         1.625rem;
$input-btn-font-size:         1.125rem;
$input-btn-line-height:       1.1;

$input-btn-focus-color-opacity: 1;
$input-btn-focus-color:         $primary;
$input-btn-focus-box-shadow:    none;

$input-btn-border-width:      $border-width;
// scss-docs-end input-btn-variables

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-font-size:               $font-size-base;
$btn-font-weight:             $font-weight-semibold;
$btn-box-shadow:              none;
$btn-focus-box-shadow:        none;
$btn-line-height:             1.1;
$btn-padding-y:               1rem;
$btn-padding-x:               1.5rem;
$btn-link-color:              $primary;
// Forms

// scss-docs-start form-text-variables
$form-text-margin-top:                  1.25rem;
$form-text-font-size:                   1.125rem;
$form-text-font-weight:                 $font-weight-normal;
$form-text-color:                       $primary;
// scss-docs-end form-text-variables

// scss-docs-start form-input-variables
$input-padding-y:                       1.438rem;
$input-padding-x:                       1.875rem;
$input-font-size:                       1em;
$input-font-weight:                     $font-weight-normal;

$input-bg:                              transparent;
$input-disabled-bg:                     $gray-100;

$input-color:                           $primary;
$input-border-color:                    rgba($primary, 0.15);

$input-border-radius:                   2rem;
$input-border-radius-lg:                35px;

$input-focus-bg:                        $input-bg;
$input-focus-border-color:              $primary;
$input-focus-box-shadow:                $box-shadow;

$input-placeholder-color:               $primary;
$input-plaintext-color:                 $primary;
// scss-docs-end form-input-variables

// scss-docs-start form-floating-variables
$form-floating-height:            add(3.5rem, $input-height-border);
$form-floating-line-height:       1.25;
$form-floating-padding-x:         1.875rem;
$form-floating-padding-y:         1rem;
$form-floating-input-padding-t:   1.625rem;
$form-floating-input-padding-b:   .625rem;
$form-floating-label-opacity:     0.7;
$form-floating-label-transform:   scale(.8) translateY(-.4rem) translateX(.4rem);
$form-floating-transition:        opacity .1s ease-in-out, transform .1s ease-in-out;
// scss-docs-end form-floating-variables


// scss-docs-start form-check-variables
$form-check-input-width:                  1.625rem;
$form-check-min-height:                   1.625rem;
$form-check-padding-start:                $form-check-input-width + 1.25rem;

$form-check-input-bg:                     transparent;
$form-check-input-border:                 solid 1px $primary;
$form-check-input-border-radius:          0;
$form-check-input-focus-border:           $form-check-input-border;
$form-check-input-focus-box-shadow:       $input-btn-focus-box-shadow;

$form-check-input-checked-color:          $secondary;
$form-check-input-checked-bg-color:       $form-check-input-bg;
$form-check-input-checked-border-color:   $form-check-input-border;
$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");
// scss-docs-end form-check-variables

// Navs

// scss-docs-start nav-variables
$nav-link-padding-y:                1rem;
$nav-link-padding-x:                1.875rem;
$nav-link-font-size:                1rem;
$nav-link-font-weight:              $font-weight-semibold;
$nav-link-color:                    $primary;
$nav-link-hover-color:              $nav-link-color;
// scss-docs-end nav-variables

// Navbar
$navbar-nav-link-padding-x:         $nav-link-padding-x;

$navbar-toggler-padding-y:          $nav-link-padding-x;
$navbar-toggler-padding-x:          $nav-link-padding-y;
$navbar-toggler-border-radius:      0;

// scss-docs-start navbar-theme-variables
$navbar-dark-color:                 $white;
$navbar-dark-hover-color:           $red;
$navbar-dark-active-color:          $navbar-dark-active-color;
$navbar-dark-disabled-color:        $gray-100;

$navbar-light-color:                $primary;
$navbar-light-hover-color:          $primary;
$navbar-light-active-color:         $red;
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: transparent;
// scss-docs-end navbar-theme-variables


// Pagination

// scss-docs-start pagination-variables
$pagination-padding-y:              0;
$pagination-padding-x:              0.438rem;

$pagination-font-size:              1.125rem;

$pagination-color:                  $primary;
$pagination-bg:                     transparent;
$pagination-border-radius:          0;
$pagination-border-width:           0;

$pagination-focus-color:            $red;
$pagination-focus-bg:               transparent;
$pagination-focus-box-shadow:       none;

$pagination-hover-color:            $red;
$pagination-hover-bg:               transparent;
$pagination-hover-border-color:     transparent;

$pagination-active-color:           $white;
$pagination-active-bg:              $red;
$pagination-active-border-color:    $red;

$pagination-disabled-color:         $gray-400;

// scss-docs-end pagination-variables

// Alerts
// scss-docs-start alert-variables
$alert-padding-y:               0.938rem;
$alert-padding-x:               0.938rem;
$alert-margin-bottom:           1rem;
$alert-border-radius:           $border-radius;
$alert-link-font-weight:        $font-weight-semibold;
$alert-border-width:            $border-width;
$alert-bg-scale:                100%;
$alert-border-scale:            100%;
$alert-color-scale:             0;
// scss-docs-end alert-variables

// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-min-width:                10rem;
$dropdown-padding-x:                0;
$dropdown-padding-y:                1.25rem;
$dropdown-spacer:                   .125rem;
$dropdown-font-size:                $font-size-base;
$dropdown-color:                    $primary;
$dropdown-bg:                       $white;
$dropdown-border-color:             $primary;
$dropdown-border-radius:            $border-radius;
$dropdown-border-width:             $border-width;
$dropdown-box-shadow:               $box-shadow;

$dropdown-link-color:               $primary;
$dropdown-link-hover-color:         $secondary;
$dropdown-link-hover-bg:            $white;

$dropdown-link-active-color:        $dropdown-link-hover-color;
$dropdown-link-active-bg:           $dropdown-link-hover-bg;

$dropdown-item-padding-y:           0.5rem;
$dropdown-item-padding-x:           1.25rem;

// Modals

// scss-docs-start modal-variables
$modal-backdrop-bg:                 $body-bg;
$modal-backdrop-opacity:            0.95;
$modal-dialog-margin:               2.5rem;
// scss-docs-end modal-variables



// RFS
$rfs-breakpoint: 1600px;
