html {
  &:not(.site-menu-open) #site-nav-menu {
    opacity: 0;
    pointer-events: none;
    user-select: none;
    visibility: hidden;
  }
  &.site-menu-open {
    overflow: hidden;
  }
}

#site-nav-menu {

  z-index: $zindex-tooltip * 9;
  background: $gradient;

  .nav-primary {
    li {
      a {
        position: relative;
        display: block;
        letter-spacing: $letter-spacing;
        padding: 30px 0;

        span {
          line-height: 1;
        }

        svg {
          position: absolute;
          bottom: 36px;
          right: $grid-gutter-width;
          opacity: 0;
          transition: $transition-base;
        }

        &:hover,
        &:focus,
        &.active {
          span:first-child {
            color: $secondary;
          }
          svg {
            opacity: 1;
            right: 0;
          }
        }
      }
      + li a {
        border-top: solid 1px $primary;
      }
    }
  }

  .nav-secondary {
    margin-top: $grid-gutter-width * 0.75;
    li {
      &:not(:last-child):after {
        border: solid 1px $secondary;
        background-color: transparent;
      }
    }
  }

  .container-fluid,
  .row {
    height: 100%;
  }

  .row {
    @include media-breakpoint-down(md) {
      display: flex;
      align-items: inherit;
      justify-content: space-between;
      flex-direction: column;
      height: 100%
    }
  }

  .container-fluid {
    @include media-breakpoint-up(md) {
      padding-top: 10vh;
      padding-bottom: 10vh;
    }
  }

  .nav-image {
    height: 50vh;
    width: 50vh;
    transform: translate(20%,20%);
    .menu-img-front {
      opacity: 0;
      transform: scale(1.2);
      pointer-events: none;
    }
  }

  .split-text {
    .line {
      display: inline-block !important;
    }
  }

}
