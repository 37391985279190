main#page-team {
  .cross {
    max-width: 115px;
    margin: 0 auto;
  }
  .list-bullet {
    @include media-breakpoint-down(sm) {
      li:not(:last-child):after{
        display: none;
      }
    }
  }
  #team-grid {
    .grid-spacer {
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
      }
    }
    .c-card-team-wrapper {
      &:nth-child(4n+4) {
        @include media-breakpoint-up(md) {
          display: none;
        }
        @include media-breakpoint-up(xl) {
          display: block;
        }
      }
      &:nth-child(6n+6) {
        @include media-breakpoint-up(xl) {
          display: none;
        }
      }
      &.grid-spacer {
        &:last-child {
          display: none;
        }
      }
    }
  }
}
