.l-widget--image-two-columns {
  @include media-breakpoint-only(xs) {
    .container-fluid {
      padding: 0;
    }
    img {
      position: relative;
      right: -1.25rem;
      left: -1.25rem;
      width: calc(100% + 2.5rem);
      border: solid 2px $body-bg;
      max-width: unset;
    }
  }
}
