.l-site-footer {

  &,
  a {
    color: $white;
    text-decoration: none;
  }

  hr {
    border-color: rgba($white, 0.15);
  }

  a {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .list-contact {
    li {
      a {
        display: block;
        color: $white;
        text-decoration: none;
        letter-spacing: $letter-spacing;
        line-height: $line-height-base;
      }
      + li {
        margin-top: 10px;
      }
    }
  }
  .list-social {
    margin-right: -5px;
    margin-left: -5px;
    li {
      padding-right: 5px;
      padding-left: 5px;
      a {
        min-width: $grid-gutter-width;
      }
    }
  }

  .locations {
    address {
      margin-bottom: 0;
      strong,
      span {
        display: block;
        color: $white;
        font-weight: 400;
        letter-spacing: $letter-spacing;
      }
      strong {
        margin: 0 0 6px 0;
        color: $white;
        font-weight: $font-weight-semibold;
      }
      span + span {
        margin: 4px 0 0 0;
      }
    }
  }

  .partner {
    display: block;
    background-color: rgba($white, 0.05);
    border: solid 1px transparent;
    border-radius: $border-radius;
    padding: 25px 10px;
    text-align: center;
    transition: $transition-base;
    img {
      width: 50%;
      max-width: 220px;
      margin: 0 auto;
    }
    &:hover,
    &:focus {
      border-color: $white;
    }
  }

  .meta {
    p {
      letter-spacing: $letter-spacing;
    }
  }

  .quote {
    position: relative;
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      z-index: 0;
      stroke-width: 0.1;
      height: 100%;
    }
    p {
      position: relative;
      z-index: 2;
    }
  }

  .footer-bottom {
    padding-top: 5px;
    padding-bottom: 20px;
    @include media-breakpoint-up(md) {
      padding-bottom: 5px;
    }
  }

  #logos {
    margin-right: -15px;
    margin-left: -15px;
    img {
      height: $grid-gutter-width;
      margin: 15px;
    }
  }

}
