.btn {
  background-image: none;
}

.btn-primary-underline,
.btn-outline-primary,
.btn-primary {
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height;
  letter-spacing: $letter-spacing;
  padding: $btn-padding-y $btn-padding-x;
}

.btn-icon-text {

  $iconSize: 14px;

  border: solid 1px $primary;
  background-color: $primary;
  display: inline-flex;
  align-items: center;
  position: relative;
  span:not(.text) {
    position: absolute;
    left: $btn-padding-x;
    transition: $transition-base;
    svg {
      position: relative;
      top: -1px;
      stroke-width: 3;
    }
  }
  .text {
    font-weight: $font-weight-semibold;
    letter-spacing: $letter-spacing;
    padding-right: 0;
    padding-left: calc(#{$iconSize} + 20px);
    transition: $transition-base;
  }
  &:hover,
  &:focus {
    span:not(.text) {
    left: calc(100% - #{$btn-padding-x} - #{$iconSize});
    }
    .text {
      padding-right: calc(#{$iconSize} + 20px);
      padding-left: 0;
    }
  }
  &.btn-outline {
    background-color: transparent;
    color: $primary;
  }
}
.btn-primary-underline {
  border-radius: 0;
  padding: 0 0 5px 0;
  transition: $transition-base;
  box-shadow: none;
  background: transparent;
  &,
  &:hover,
  &:focus,
  &:active,
  &:active:focus,
  &:visited {
    background: transparent;
    border: 0;
    border-bottom: solid 1px $primary;
    box-shadow: $box-shadow;
  }
  &:hover,
  &:focus {
    border-bottom-color: $secondary;
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value, $white, transparent, $value, $value);
  }
}


