.l-widget--two-images-grid {
  .image-first{
    max-width: unset;
    width: calc(125%);
  }
  .image-second {
    @include media-breakpoint-only(xs) {
      margin-left: 25%;
    }
  }
}
