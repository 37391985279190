main#page-home {

  $headerHeight: 111px;
  $headerHeightSM: 151px;
  $jumbotronBreakPoint: lg;

  #jumbotron {

    border-bottom: solid 1px rgba($primary, 0.15);
    min-height: 100vh;

    .icon.icon-cross {
      opacity: 0.08;
      height: 80%;
      width: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      stroke-width: 0.15;
      @include media-breakpoint-up($jumbotronBreakPoint) {
        stroke-width: 0.04;
      }
    }

    .img-circle {
      aspect-ratio: 1/1;
      border-radius: 100%;
      &.img-circle-right {
        @include media-breakpoint-up($jumbotronBreakPoint) {
          right: auto;
          left: $grid-gutter-width * 0.5;
        }
      }
      @include media-breakpoint-up($jumbotronBreakPoint) {
        position: absolute;
        height: 80%;
        max-height: 1000px;
        top: 50%;
        right: $grid-gutter-width * 0.5;
        transform: translate(0%, -50%);
      }
      @include media-breakpoint-down($jumbotronBreakPoint) {
        position: relative;
        right: -($grid-gutter-width * 0.5);
        left: -($grid-gutter-width * 0.5);
        width: calc(100% + #{$grid-gutter-width});
        border: solid 2px $body-bg;
      }
    }

    .container-fluid {
      padding-top: $headerHeight;
      @include media-breakpoint-up(sm) {
        padding-top: $headerHeightSM;
      }
    }

    .summary {
      letter-spacing: $letter-spacing;
      @include media-breakpoint-up(sm) {
        @include font-size(18px);
      }
    }

    @include media-breakpoint-up($jumbotronBreakPoint) {
      height: 100vh;
      border-bottom: 0;
      min-height: unset;
      [class*='col-'],
      .d-flex {
        height: 100%;
      }
    }

  }

  #clients {
    a {
      img {
        mix-blend-mode: multiply;
        filter: saturate(0);
        transition: $transition-base;
      }
      &:hover,
      &:focus {
        img {
          filter: saturate(1);
        }
      }
    }
    .row {
      border-top: solid 1px $primary;
      border-left: solid 1px $primary;
      [class*='col-'] {
        border-bottom: solid 1px $primary;
        border-right: solid 1px $primary;
      }
    }
  }

}
