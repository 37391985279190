.l-widget--three-images-grid {
  .image-first,
  .image-third {
    max-width: unset;
    width: calc(125%);
  }
  .image-second {
    @include media-breakpoint-up(sm) {
      width: 75%;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
    }
  }
  .image-third {
    @include media-breakpoint-only(xs) {
      width: 150%;
    }
  }
}
