.l-widget--timeline {
  .c-timeline {
    .year {
      @include media-breakpoint-only(xs) {
        color: $danger;
        margin-bottom: $grid-gutter-width * 0.5;
      }
     @include media-breakpoint-up(sm) {
       border-radius: 200px;
       display: flex;
       justify-content: center;
       align-items: center;
       aspect-ratio: 1/1;
       border: solid 1px $primary;
       background-color: $body-bg;
       margin: 0;
     }
    }
    + .c-timeline {
      margin-top: $grid-gutter-width;
      .year {
        position: relative;
        &:before {
          @include media-breakpoint-up(sm) {
            content: "";
            display: block;
            height: calc(100% + #{$grid-gutter-width} + 2px);
            width: 1px;
            background-color: $primary;
            position: absolute;
            bottom: 0;
            left: 50%;
            z-index: -1;
          }
        }
      }
    }
  }
}
