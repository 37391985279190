textarea.form-control {
  min-height: 7rem;
}

.form-check {
  padding-left: $form-check-input-width + 0.938rem;

  .form-check-input {
    margin-left: -$form-check-input-width - 0.938rem;
    &:checked[type='checkbox'] {
      background: transparent escape-svg($form-check-input-checked-bg-image) no-repeat center center;
      background-size: 18px 18px;
    }
  }

  label {
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    padding-top: 1px;

    a {
      color: $input-color;
    }
  }
}


.validation-feedback.is-invalid {
  input:not(:valid),
  textarea:not(:valid) {
    border-color: $danger;
  }
  .invalid-feedback {
    display: block;
  }
}

.was-validated {

  .form-check-input,
  .form-select,
  .form-control {
    &:valid,
    &.valid {
      border-color: $input-border-color;
      box-shadow: none;
      &:focus {
        border-color: $input-border-color;
        box-shadow: none;
      }
    }
    &:invalid,
    &.is-invalid {
      border-color: $form-feedback-invalid-color;
      box-shadow: none;
      &:focus {
        border-color: $form-feedback-invalid-color;
        box-shadow: none;
      }
    }
  }
}

.valid-feedback,
.invalid-feedback {
  flex: 1 1 100%;
  font-weight: $font-weight-semibold;
  margin: $form-feedback-margin-top 0 0 0;
}

.form-row-condensed {
  margin-right: -5px;
  margin-left: -5px;
  [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px;
  }
}
