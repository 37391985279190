.c-service-item {
  text-decoration: none;
  color: $primary;
  display: flex;
  border-top: solid 1px rgba($primary, 0.15);

  svg:not(.icon) {
    @include media-breakpoint-only(xs) {
      width: 50%;
      margin: 0 auto 0 0;
    }
  }

  h4 {
    transition: $transition-base;
  }

  .intro {
    line-height: 1.8;
    letter-spacing: $letter-spacing;
  }

  circle,
  path {
    fill: transparent;
    stroke: $primary;
    stroke-width: 3;
    @include media-breakpoint-up(md) {
      stroke-width: 2;
    }
  }

  &:last-child {
    border-bottom: solid 1px rgba($primary, 0.15);
  }

  &:hover,
  &:focus {
    color: $primary;
    h4 {
      color: $secondary;
    }
  }

}
